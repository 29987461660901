import { Dispatch, SetStateAction, createContext } from "react";


interface ContextMenuItem {
    label: string;
    action: () => void;
    variant?: string;
    icon?: string;
    disabled?: boolean;
}

const SharedStateContext = createContext<{
    sharedState: string[];
    setSharedState: Dispatch<SetStateAction<string[]>>;

    showMenu: boolean;
    position: { x: number; y: number}
    menuItems: ContextMenuItem[];
    setMenuItems: Dispatch<SetStateAction<ContextMenuItem[]>>;
    showContextMenu: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    hideContextMenu: () => void;
    }
    >({sharedState: [],
        setSharedState: () => {},

        showMenu: false,
        position: { x: 0, y: 0 },
        menuItems: [],
        setMenuItems: () => {},
        showContextMenu: () => {},
        hideContextMenu: () => {},
})

export default SharedStateContext