import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DisplayErrors from "../elements/DisplayErrors";
import { fileDTO } from "../../models/fileModel";
import api, { endpoints } from "../../utils/api";
import AuthenticationContext from "../auth/AuthenticationContext";
import useFeedback from "../elements/customHooks/useFeedback";

interface FileDeleteModalProps {
    file: fileDTO;
    show: boolean;
    handleClose: (value: boolean) => void;
}

export default function FileDeleteModal({ file, show, handleClose }: FileDeleteModalProps) {
    const [errors, setErrors] = React.useState<string[]>([]);
    const { setFoundedFiles } = useContext(AuthenticationContext);
    const { done } = useFeedback();

    async function deleteFile() {
        try {
            await api.delete(`${endpoints.files}/${file.id}`)
                .then(() => {
                    setFoundedFiles([]);
                    done("File deleted successfully", "success", 3000);
                    handleClose(false);
                });
        } catch (error) {
            if (error && error.response) {
                setErrors(error.response.data);
            }
        }
    }

    return (
        <Modal show={show} onHide={() => handleClose(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DisplayErrors errors={errors} />
                <p>Do you want delete the file <strong>{file.name}</strong>?</p>
                <p>This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteFile}>
                    <i className="bi bi-trash-fill"></i> Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}