import React, { useState } from "react";
import { DateTime } from "luxon";
import EntityIndex from "../elements/EntityIndex";
import FileSubbuttons from "./FileSubbuttons";
import { adjustByteSize, fileDTO } from '../../models/fileModel';
import api, { endpoints } from "../../utils/api";
import { truncateFileName } from "../../utils/truncateFileName";
import { CheckIcon, ClockIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

//@ts-ignore
import Table from 'react-table-lite';

interface FileTableListProps {
    fileModalOpenHandler: (file: fileDTO) => void;
    toggleMenu: (menuId: string) => void;
    openMenuId: string | null;
    foundedFiles: fileDTO[];
    getFileBlobHandler: (fileId: string) => Promise<void>;
}

const FileTableList: React.FC<FileTableListProps> = ({
                                                         fileModalOpenHandler,
                                                         toggleMenu,
                                                         openMenuId,
                                                         foundedFiles,
                                                         getFileBlobHandler
                                                     }) => {
    const [newLoadFile, setNewLoadFile] = useState(false);
    const [sortBy, setSortBy] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    function handleReindex(fileId: string) {
        setNewLoadFile(true);
        setTimeout(() => {
            api.post(`${endpoints.files}/index/${fileId}`)
                .then(() => setNewLoadFile(true))
                .catch((error) => console.error('Reindex failed:', error))
                .finally(() => setNewLoadFile(false));
        }, 1000);
    }

    const canReindex = (file: fileDTO) => {
        const fileAge = DateTime.now().diff(DateTime.fromISO(file.createdDate, { zone: "utc" }).setZone(userTimeZone), 'minutes').minutes;
        return file.indexStatus === 'Pending' && fileAge > 30;
    };

    return (
        <div id="file-list">
            <EntityIndex<fileDTO>
                entityName="File"
                title=""
                url={endpoints.files}
                foundedFiles={foundedFiles}
                newLoadFile={newLoadFile}
                sortBy={sortBy}
                sortOrder={sortOrder}
            >
                {(files, _, sortItems) => (
                    <Table
                        data={files}
                        headers={["name", "source", "indexStatus", "createdDate", "size", "actions"]} // Added "source"
                        customHeaders={{
                            "name": "Name",
                            "source": "Source", // Header for Source
                            "indexStatus": <th className="index-status-column">Status</th>, // Hide it on mobile
                            "createdDate": "Date",
                            "size": "Size",
                            "actions": " "
                        }}
                        customRenderCell={{
                            'name': (file: fileDTO) => (
                                <span
                                    onClick={() => getFileBlobHandler(file.id)}
                                    style={{ cursor: 'pointer', color: '#176AD6', textDecoration: 'underline' }}
                                >
                                    {truncateFileName(file.name, 50)}
                                </span>
                            ),
                            'source': (file: fileDTO) => (
                                <span className="source-cell">
                                    {file.source}
                                </span>
                            ),
                            'indexStatus': (file: fileDTO) => (
                                <div className="icon-container index-status-column">
                                    {file.indexStatus === 'Indexed' && <CheckIcon className="icon-indexed" />}
                                    {file.indexStatus === 'Pending' && (
                                        <ClockIcon
                                            className="icon-pending"
                                            onClick={() => canReindex(file) && handleReindex(file.id)}
                                            style={{ cursor: canReindex(file) ? 'pointer' : 'default', color: canReindex(file) ? 'red' : 'orange' }}
                                        />
                                    )}
                                    {file.indexStatus === 'Error' && (
                                        <XCircleIcon
                                            className="icon-error"
                                            onClick={() => handleReindex(file.id)}
                                            style={{ cursor: 'pointer', color: 'red' }}
                                        />
                                    )}
                                    {file.indexStatus === 'NotSupported' && <ExclamationCircleIcon className="icon-not-supported" />}
                                    <span className="tooltip-text">
                                        {file.indexStatus === 'Indexed' ? "Document is ready for search" :
                                            file.indexStatus === 'Pending' ? "Index Pending. Document is not ready for search." :
                                                file.indexStatus === 'Error' ? "Indexing Error. Click to retry." :
                                                    "File type not supported for indexing."}
                                    </span>
                                </div>
                            ),
                            'size': (file: fileDTO) => adjustByteSize(file.size),
                            'createdDate': (file: fileDTO) =>
                                DateTime.fromISO(file.createdDate, { zone: 'utc' })
                                    .setZone(userTimeZone).toLocaleString(DateTime.DATETIME_MED),
                            'actions': (file: fileDTO) => (
                                <div id="fl-item">
                                    <FileSubbuttons
                                        file={file}
                                        isOpen={openMenuId === file.id}
                                        toggleMenu={() => toggleMenu(file.id)}
                                    />
                                </div>
                            )
                        }}
                        sortBy={["name", "source", "createdDate", "size"]}
                        onSort={(e: Event, _: fileDTO[], columnName: string, order: string) => {
                            sortItems(columnName, order);
                        }}
                        cellStyle={{ position: 'relative' }}
                    />
                )}
            </EntityIndex>
        </div>
    );
};

export default FileTableList;
