import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import api, { endpoints } from '../utils/api';
import { useContext, useEffect, useState } from 'react';

import AuthenticationContext from "./auth/AuthenticationContext";
import DisplayErrors from './elements/DisplayErrors';
import FeedbackToast from './elements/FeedbackToast';
import Layout from './Layout';
import ProfilePic from './elements/ProfilePic';
import ResetPasswordModal from './auth/PasswordResetModal';
import { User } from '../models/auth.models';
import useFeedback from './elements/customHooks/useFeedback';
import { useNavigate } from 'react-router-dom';

export default function SettingsPage() {
    const navigate = useNavigate();
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [hideSubscriptionModal, setHideSubscriptionModal] = useState(false);
    const [deleteErrors, setDeleteErrors] = useState<string[]>([]);
    const { done, error } = useFeedback();
    const [userData, setUserData] = useState<User | null | undefined>({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
    });

    const {
        setIsLoggedIn,
        setUser,
        meRef,
        isSubscribed
    } = useContext(AuthenticationContext);

    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
    const priceId = process.env.REACT_APP_STRIPE_PRICE_ID || "price_1QoRC5EsjTnIb5gkwSLdaOtB";

    const [updatedUserData, setUpdatedUserData] = useState<User | null | undefined>({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
    });

    const [isUpdateUser, setUpdateUser] = useState(false);

    useEffect(() => {
        !isUpdateUser &&
            (async () => {
                try {
                    const response = await api.get(`${endpoints.accounts}/user`);
                    if (response.status === 200) {
                        setUpdatedUserData(response.data);
                        setUserData(response.data);
                        setUpdateUser(true);
                    }
                } catch (error) {
                    setErrors([error.message]);
                }
            })();
    }, [isUpdateUser]);

    useEffect(() => {
        const fetchSubscription = async () => {
            setIsLoadingSubscription(true);
            try {
                const response = await api.get<Subscription>(`${endpoints.payment}/subscriptionDetails`);
                if (response.status === 200) {
                    setSubscription(response.data);
                }
            } catch (error) {
                console.error("Failed to fetch subscription:", error);
                setSubscription(null);
            }
            setIsLoadingSubscription(false);
        };

        const fetchInvoices = async () => {
            setIsLoadingInvoices(true);
            try {
                const response = await api.get<Invoice[]>(`${endpoints.payment}/invoices`);
                if (response.status === 200) {
                    setInvoices(response.data);
                }
            } catch (error) {
                console.error("Failed to fetch invoices:", error);
                setInvoices([]);
            }
            setIsLoadingInvoices(false);
        };

        fetchSubscription();
        fetchInvoices();
    }, []);

    const saveChanges = async () => {
        try {
            const response = await api.put(`${endpoints.accounts}/user`, JSON.stringify(userData), {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                done('Changes saved', 'success', 3000);
                setUpdateUser(false);
            }
        } catch (error) {
            setErrors([error.message]);
        }
    };

    const deleteAccount = async () => {
        try {
            await api.delete(`${endpoints.accounts}/user`);
            // Clear user context and log out
            setIsLoggedIn(false);
            setUser(null);
            meRef.current = ""; // Clear the email reference

            // Close modal and navigate to the login page or homepage
            setShowDeleteAccountModal(false);
            navigate('/');
            done('Account deleted successfully', 'success', 3000);
        } catch (error) {
            if (error && error.response) {
                setDeleteErrors(error.response.data);
            } else {
                setDeleteErrors(["An unexpected error occurred"]);
            }
            console.error('Error deleting account: ', error);
        }
    };

    const createCheckoutSession = async (priceId: string) => {
        api.post(endpoints.payment + `/createCheckout`, JSON.stringify(priceId), {
            headers: { 'Content-Type': 'application/json' },
        }).then(response => {
            if (response.status === 200) {
                const data = response.data;
                window.location.href = data.url;
            }
            throw new Error('Failed to create checkout session');
        })
    };

    const cancelSubscription = async () => {
        try {
            const response = await api.post(`${endpoints.payment}/cancel`);
            if (response.status === 200) {
                done("Subscription canceled successfully", "success", 3000);
                setSubscription((prev) => prev ? { ...prev, status: "canceled" } : null);
            }
        } catch (exception) {
            console.error("Failed to cancel subscription:", error);
            error("Failed to cancel subscription");
        }
    };

    const handleModalClose = () => setShowPasswordChangeModal(false);

    return (
        <Layout updatedUserData={updatedUserData}>
            <FeedbackToast />
            <Modal
                show={!isLoadingSubscription && isSubscribed === false && !hideSubscriptionModal}
                centered
                onHide={() => setHideSubscriptionModal(true)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        An active subscription is required to use MyUnicBrain.
                        <br /><br />
                        Start your one-time <strong>14-day free trial</strong> today!
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => createCheckoutSession(priceId)}>
                        Subscribe Now
                    </Button>
                </Modal.Footer>
            </Modal>

            <Form id="profile-container">
                <Row id="profile-top-row">
                    <ProfilePic show={true} />
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="fn">
                        <FloatingLabel label="First Name">
                            <Form.Control
                                type="text"
                                value={userData?.firstName}
                                onChange={(un) =>
                                    setUserData((prev: any) => ({
                                        ...prev,
                                        firstName: un.target.value,
                                    }))
                                }
                            />
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group as={Col} controlId="ln">
                        <FloatingLabel label="Last Name">
                            <Form.Control
                                type="text"
                                value={userData?.lastName}
                                onChange={(un) =>
                                    setUserData((prev: any) => ({
                                        ...prev,
                                        lastName: un.target.value,
                                    }))
                                }
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="mail">
                    <FloatingLabel label="Email">
                        <Form.Control
                            type="email"
                            value={userData?.email}
                            onChange={(un) =>
                                setUserData((prev: any) => ({
                                    ...prev,
                                    email: un.target.value,
                                }))
                            }
                        />
                    </FloatingLabel>
                </Form.Group>

                <DisplayErrors errors={errors} />

                <Row className="mb-3 justify-content-start">
                    <Col xs="auto" className="mb-2 mb-md-0">
                        <Button
                            id="profile-save"
                            variant="primary"
                            onClick={() => saveChanges()}
                        >
                            Save changes
                        </Button>
                    </Col>
                    <Col xs="auto" className="mb-2 mb-md-0">
                        <Button
                            id="change-password-btn"
                            variant="secondary"
                            onClick={() => setShowPasswordChangeModal(true)}
                        >
                            Change Password
                        </Button>
                    </Col>
                </Row>

                <div className="section-divider mt-4 mb-4"></div>

                <div className="subscription-section">
                    <h2 className="section-title">Subscription</h2>
                    {isLoadingSubscription ? (
                        <div className="loading-indicator">
                            <p>Loading subscription details...</p>
                        </div>
                    ) : subscription ? (
                        <div className="subscription-details">
                            <div className="subscription-info">
                                <div className="info-row">
                                    <span className="info-label">Status:</span>
                                    <span className={`info-value status-${subscription.status}`}>
                                        {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                                    </span>
                                </div>
                                <div className="info-row">
                                    <span className="info-label">Valid Until:</span>
                                    <span className="info-value">{new Date(subscription.currentPeriodEnd).toLocaleDateString()}</span>
                                </div>
                            </div>
                            <div className="subscription-actions mt-3">
                                {subscription.status === "active" || subscription.status === "trialing" ? (
                                    <Button variant="danger" onClick={() => setShowCancelSubscriptionModal(true)}>
                                        Cancel Subscription
                                    </Button>
                                ) : (
                                    <Button variant="primary" onClick={() => createCheckoutSession(priceId)}>
                                        Subscribe Now
                                    </Button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="subscription-empty">
                            <p>No active subscription found.</p>
                            <Button variant="primary" onClick={() => createCheckoutSession(priceId)}>
                                Subscribe Now
                            </Button>
                        </div>
                    )}
                </div>

                <div className="section-divider mt-4 mb-4"></div>

                <div className="invoices-section">
                    <h2 className="section-title">Billing History</h2>
                    {isLoadingInvoices ? (
                        <div className="loading-indicator">
                            <p>Loading invoices...</p>
                        </div>
                    ) : invoices.length > 0 ? (
                        <Table striped bordered hover className="invoice-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td>{new Date(invoice.createdDate).toLocaleDateString()}</td>
                                        <td>{invoice.amountPaid.toFixed(2)} {invoice.currency.toUpperCase()}</td>
                                        <td>
                                            <span className={`status-badge status-${invoice.status}`}>
                                                {invoice.status}
                                            </span>
                                        </td>
                                        <td>
                                            <a href={invoice.invoiceUrl} target="_blank" rel="noopener noreferrer" className="invoice-link">
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="invoices-empty">
                            <p>No billing history found.</p>
                        </div>
                    )}
                </div>

                <div className="section-divider mt-4 mb-4"></div>

                <div className="danger-zone">
                    <h2 className="danger-zone-title">Danger Zone</h2>
                    <p className="danger-description">Permanently delete your account and all associated data. This action cannot be undone.</p>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setShowDeleteAccountModal(true)}
                        className="delete-account-btn"
                    >
                        Delete Account
                    </Button>
                </div>

                <ResetPasswordModal
                    show={showPasswordChangeModal}
                    email={userData?.email}
                    handleClose={handleModalClose}
                    changePassword={true}
                />

                {/* Cancel Subscription Confirmation Modal */}
                <Modal
                    show={showCancelSubscriptionModal}
                    onHide={() => setShowCancelSubscriptionModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to cancel your subscription?</p>
                        <p>This will stop future billing and your access will be revoked at the end of your current period.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCancelSubscriptionModal(false)}>
                            Keep Subscription
                        </Button>
                        <Button variant="danger" onClick={() => {
                            cancelSubscription();
                            setShowCancelSubscriptionModal(false);
                        }}>
                            Cancel Subscription
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Account Confirmation Modal */}
                <Modal
                    show={showDeleteAccountModal}
                    onHide={() => setShowDeleteAccountModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DisplayErrors errors={deleteErrors} />
                        <p>Are you sure you want to delete your account?</p>
                        <p><strong>This will permanently delete:</strong></p>
                        <ul>
                            <li>Your account information</li>
                            <li>All your files and data</li>
                            <li>Your subscription information</li>
                        </ul>
                        <p className="text-danger fw-bold">This action cannot be undone.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteAccountModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteAccount}>
                            <i className="bi bi-trash-fill"></i> Delete Account
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </Layout>
    );
}
