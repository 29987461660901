import { Button } from "react-bootstrap";
import { fileDTO } from "../../models/fileModel"
import { useContext, useEffect, useState } from "react";
import FileShareModal from "./FileShareModal";
import SharedStateContext from "./SharedStateContext";
import useClickOutside from "./useClickOutside";
import FileRenameModal from "./FileRenameModal";
import FileDeleteModal from "./FileDeleteModal";
import api, { endpoints } from "../../utils/api";
import AuthenticationContext from "../auth/AuthenticationContext";


interface subFileProps {
    file: fileDTO;
    isOpen: boolean;
    toggleMenu: () => void;
}


export default function FileSubbuttons({file, isOpen, toggleMenu}: subFileProps) {
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const {setMenuItems} = useContext(SharedStateContext)
    const {setFoundedFiles} = useContext(AuthenticationContext)
    const {isOpen: isActive, setIsActive, ref} = useClickOutside()
    
    // Helper to check if file is local
    const isLocalFile = file.source?.toLowerCase() === 'local' || !file.source

    // see documentation for this mess
    // basically whenever you click outside of a pressed button
    // it collapses the menu via a custom hook
    useEffect(() => {
        setIsActive(isOpen)
    }, [isOpen, setIsActive])

    useEffect(() => {
        const menuItemsList = [
            {label: "Download", action: () => downloadFile(file), variant: "primary", icon: "bi-download"},
            {
                label: "Share", 
                action: () => isLocalFile ? setShowShareModal(true) : null, 
                variant: isLocalFile ? "primary" : "secondary", 
                icon: "bi-share-fill",
                disabled: !isLocalFile
            },
            {
                label: "Rename", 
                action: () => isLocalFile ? setShowRenameModal(true) : null, 
                variant: isLocalFile ? "light" : "secondary", 
                icon: "bi-vector-pen",
                disabled: !isLocalFile
            },
            {
                label: "Delete", 
                action: () => isLocalFile ? setShowDeleteModal(true) : null, 
                variant: isLocalFile ? "danger" : "secondary", 
                icon: "bi-trash-fill",
                disabled: !isLocalFile
            }
        ];
        
        setMenuItems(menuItemsList);
    }, [])


    function downloadFile(file: fileDTO) {
        api({
            url: `${endpoints.files}/download/${file.id}`,
            method: 'GET',
            responseType: "blob"
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data)

                // create anachor HTLM element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', file.name)
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            })
    }


    return (
        <>
            <div ref={ref}>
                <Button
                    variant="secondary"
                    id="fl-options"
                    onClick={toggleMenu}>
                    <i className="bi bi-three-dots"/>
                </Button>
                {isActive ?
                    <div id="fl-option-btns">
                        <Button variant="primary" onClick={() => downloadFile(file)}>
                            <i className="bi bi-download"></i> Download
                        </Button>
                        
                        {/* Share button - disabled for non-local files */}
                        <Button 
                            variant={isLocalFile ? "primary" : "secondary"}
                            onClick={() => isLocalFile ? setShowShareModal(true) : null}
                            disabled={!isLocalFile}
                        >
                            <i className="bi bi-share-fill"></i> Share
                        </Button>
                        
                        {/* Rename button - disabled for non-local files */}
                        <Button 
                            variant={isLocalFile ? "light" : "secondary"}
                            onClick={() => isLocalFile ? setShowRenameModal(true) : null}
                            disabled={!isLocalFile}
                        >
                            <i className="bi bi-vector-pen"></i> Rename
                        </Button>
                        
                        {/* Delete button - disabled for non-local files */}
                        <Button 
                            variant={isLocalFile ? "danger" : "secondary"}
                            onClick={() => isLocalFile ? setShowDeleteModal(true) : null}
                            disabled={!isLocalFile}
                        >
                            <i className="bi bi-trash-fill"></i> Delete
                        </Button>
                    </div> : null}
            </div>

            {showShareModal ?
                <FileShareModal
                    show={showShareModal}
                    handleClose={(value) => setShowShareModal(value)}
                    entity={{"fileId": file.id, "email": ""}}/>
                : null}

            {showRenameModal ?
                <FileRenameModal
                    show={showRenameModal}
                    handleClose={(value) => setShowRenameModal(value)}
                    file={{"fileId": file.id, "newName": file.name}}/>
                : null}

            {showDeleteModal ?
                <FileDeleteModal
                    show={showDeleteModal}
                    handleClose={(value) => setShowDeleteModal(value)}
                    file={file}/>
                : null}
        </>
    )
}